<template>
  <b-col cols="12" lg="6" class="bg-gray">
    <b-row class="hp-image-row h-100">
      <b-col cols="12" class="px-4 pb-0">
        <Logo />
      </b-col>
      <b-col cols="12 px-0 py-0">
        <b-row align-v="center" align-h="center" class="w-100 mx-0">
          <b-col cols="12" class="text-center p-0">
            <img
              class="m-auto w-100"
              :src="
                require('@/assets/images/authentication/authentication-bg.svg')
              "
              alt="Background Image"
            />
          </b-col>
          <b-col cols="12" xl="9" class="text-center pb-0">
            <h2 class="hp-text-item">
              Copec Flux Proyectos
            </h2>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import Logo from "@/components/commons/Logo";

export default {
  components: {
    Logo,
  },
};
</script>
<style>
.hp-text-item {
  margin-top: -180px;
  color: #2d3436 !important;
}
</style>
