<template>
  <div class="p-4 mt-3 pb-0 d-flex align-items-center w-50">
    <b-link to="/" class="position-relative d-flex">
      <img
        width="100px"
        v-bind:src="require('@/assets/images/copec/logo-copec-flux.png')"
        alt="logo"
      />
    </b-link>
    <a class="font-weight-normal text-danger mx-1">
      v.{{ appConfig.version }}
    </a>
  </div>
</template>

<script>
import appConfig from "@/app.config";
export default {
  data() {
    return {
      appConfig,
    };
  },
};
</script>
